.main-footer {
    color: white;
    background-color: #3390FF ;
    padding-top: 1em;
    position: relative;
    bottom: 0;
    width: 100%;
    padding-bottom: 1em;
    display: flex;
    justify-content: space-around;
    height: 70px;
  }

  /* .dialog{
    width: 80vw;
  } */

  .header{
    background-color: #3390FF;
    height: 80px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 10%;
  }

  .nav_text{
    list-style: none;
    color: #ffffff;
    font-weight: 300;
    margin: 0 20px;
  }

  .nav_right{
    /* float: right;
    margin-left: auto; */
    align-items: center;
    display: flex;
  }

  .nav_left{
    cursor: pointer;
  }
  
  .nav_text:hover{
    text-decoration: none;
    color: #ffffff;
  }

.loader_bg{
  position: fixed;
  z-index: 999;
  background: #ffffff;
  width: 100%;
  height: 100%;
}

.loader{
  border: 0 solid transparent;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  position: absolute;
  left: 45%;
  top: 30%;
  margin-left: auto;
}

.loader:before, .loader:after{
  content: '';
  border: 1rem solid #007bff;
  border-radius: 50%;
  width: inherit;
  height: inherit;
  position: absolute;
  top:0;
  left: 0;
  animation: loader 2s linear infinite;
  opacity: 0;
}

.loader:before{
  animation-delay: 0.5s;
}

@keyframes loader{
  0% {
    transform: scale(0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}

.searchField{
  background-color: #ffffff;
  opacity: 0.7;
  margin-bottom: 20px;
  border: 1px solid #007bff;
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 15px;
  top:50;
  justify-content: center;
  position:relative;
  
}

.searchField:hover{
  opacity: 1;
  transition: 0.5s;
  transition-property: opacity;
}

.search-icon{
  cursor: pointer;
  padding: 10px;
  text-align: center;
  font-size: 50;
  color: #ffffff;
  background: #007bff;
  border-radius: 10px;
}

.search-icon:hover{
  border-radius: 10px;
  transition: 0.5s;
  transition-property: background;
}

.card{
  margin: 10px;
  padding: 5px;
  text-align: left;
  width: 100%;
  transition: 0.2s;
  box-shadow: 5px 5px 30px -10px #756d6d;
  border-radius: 10px;margin-left: auto;
}

.card:hover{
  transform: scale(1.02);
  box-shadow: 0 0 30px -10px #007bff;
}

.tenant-logo-card{
  width: 20%;
  margin-right: 20px;
}

.tenant-details-card{
  width: 60%;
  text-align: center;
}

.tenant-button-card{
  margin-top: 20px;
}

.media-icon{
  display: flex;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
  padding: 0;
}

.card-bottom{
  display: flex;
  justify-content: space-around;
  margin-left: auto;
}

.card-top {
  display: flex;
}

.card-top-tenant{
  display: flex;
}

.businessName{
  font-size: 18px;
  font-weight: 700;
}

.confirm{
  display: flex;
  /* margin: 5px; */
  font-size: 1rem;
}

.confirm-details{
  text-align: left;
}

.confirm-title{
  text-align: left;
  font-weight: 700;
  margin:0 5px;
}

.details-form{
  margin: 0 20px;
  padding: 0 15px;
}

.button:disabled{
  cursor: not-allowed! important;
}

.button:enabled{
  cursor: pointer;
}

.modal-alert{
  width: 80%;
  margin: 0;
  font-size: 1rem;
}

@media screen and (max-width: 1000px){
  .details-form{
    margin: 1px;
    padding: 1px;
  }
  
}

@media screen and (max-width: 400px){
  .nav_text{
    /* margin: 0 1px; */
    font-size: 0.2rem;
  }
  .details-form{
    margin: 0;
    padding: 1px;
  }
}

@media screen and (max-width: 700px){
  .nav_text{
    margin: 0 5px;
    font-size: 0.7rem;
  }

  .card-bottom-btn{
    display: flex;
    justify-content: space-around;
  }

  .card-bottom{
    display: inline;
  }

  .search-icon{
    padding: 5px;
    justify-items: center;
  }

  .searchField{
    padding:5px ;
    border-radius: 5px;
  }

  #vemaslogo{
    width: 40%;
  }

  .header{
    height: 40px;
  }

  .footer-text{
    font-size: 0.7rem;
  }

  .main-footer{
    height: 50px;
  }

  .card-top {
    /* display: inline; */
    font-size: 10px;
  }

  .card-top-tenant{
    display: inline;
    font-size: 10px;
  }

  .businessName{
    font-size: 15px;
    margin-top: 10px;
  }

  .tenant-logo-card{
    width: 40%;
  }
  .card{
    margin: 5px 1px;
    padding: 0;
  }
  .confirm{
    font-size: 0.6rem;
    margin: 0;
  }

.modal-alert{
  font-size: 0.6rem;
}

.tenant-details-card{
  width: 100%;
  text-align: center;
}

}


