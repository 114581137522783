.card-list{
    display: grid;
    margin-bottom: 7%;
    grid-template-columns: repeat(2, 1fr);
    /* flex-direction: row; */
    justify-content: space-around;
    flex-flow: wrap;
    margin: 7% 12%;
    /* margin-left: auto; */
}

/* .filter-card-list{
    display: grid;
    margin-bottom: 7%;
    grid-template-columns: repeat(2, 1fr);
    justify-content: space-around;
    flex-flow: wrap;
} */

.content-icon{
    display: flex;
    align-items: center;
    justify-content: space-around;
    cursor: pointer;
    margin-top: 30px;
}

.search{
    padding-top:12%;
    /* padding-bottom:8% */
}

.content{
    display: flex;
    /* position: absolute; */
}

.jumbotron-bottom{
    display: flex;
    justify-content: space-between;
}

.tenant-image{
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    width: 80%;
}

#banner{
    width: 100%;
    height: 70vh;
    /* margin-top: 50px; */
}

.service-list{
    display: grid;
    margin-bottom: 7%;
    grid-template-columns: repeat(2, 1fr);
    /* flex-direction: row; */
    justify-content: space-around;
    flex-flow: wrap;
    margin: 3% 0;
}

.card-content{
    margin: 2% 10% ;
    padding: 0 0 5% 0;
    /* border: 2px solid #979696; */
    border-radius: 5px;
    text-align: center;
}

.card-content h4{
    color:#2B76A8;
    font-size: 1.3rem;
    /* background-color: #979696;  */
    padding: 2%;
}

.card-content span{
    text-align: left;
    margin-right: 5%;
    padding:5%;
}

.card-content-location{
    display: flex;
    margin: 5%;
}

#location-icon{
    width: 1.8rem;
    color: #ee0a0a;
    margin-right: 20px;
}

.booking-button{
    float: right;
    width: 160px;
}

.booking-open-button {
    position: fixed;
    z-index:1;
    /* background-color: #ffffff; */
    /* border: 2px solid #000000; */
    border-radius: 20px 0 0 20px;
}

.tenant-businessName{
    font-size: 2rem;
}

.tenant-logo{
  width: 10%;
  margin: 0 30px;
}

.area-list{
    display: grid;
    margin-bottom: 7%;
    grid-template-columns: repeat(4, 1fr);
    /* flex-direction: row; */
    justify-content: space-around;
    flex-flow: wrap;
    margin-top: 3%;
}

.title-area {
    /* float: left; */
    display: block;
    /* position: relative; */
    width: 100%;
    text-align: center;
    background-color: #2B76A8;
    overflow: hidden;
}

.banner-top{
    border-top: 10px solid #2B76A8;
}

.title-area h2{
    color: #ffffff;
    padding: 1%;
}

.img-content{
    /* position: absolute;  */
    /* bottom: 0px;  */
    font-size: 26px; 
    line-height: 26px; 
    width: 100%; 
    text-align: center; 
    background-color: #979696; 
    padding: 20px 0px; 
    color: #ffffff;
    opacity: 0.8;
}

table {
    border-collapse: collapse;
    width: 100%;
    padding: 10px;
}

table td{
    border-bottom: 2px solid #e2e2e2;
    padding: 5px;
    padding-left: 5px;
    text-align: left;
}

table th {
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 5px;
    text-align: left;
    color: #646464;
    border-bottom: 1px solid #ffffff;
  }

.openingHours{
    padding: 10px;
    border-bottom: 1px solid #c4c4c4;
}

.shop-closed{
    background-color:#ff6384;
    color:white;
    border-radius:20px;
    padding:2px;
    padding-left:10px;
    padding-right:10px
}

.shop-open{
    background-color:#36eb69;
    color:white;
    border-radius:20px;
    padding:2px;
    padding-left:10px;
    padding-right:10px
}

/* .container{
    border:solid 1px #d3d3d3;
    padding:30px;
    border-radius: 10px;
} */

@media screen and (max-width: 1500px){ 
    .card-list{
        margin: 7%;
    }

    /* .card-bottom-btn{
        display: flex;
        justify-content: flex-end;
    } */
}

@media screen and (max-width: 700px){ 
    .content{
        display: inline;
        /* text-align: center; */
        font-size: 10px;
    }
    .tenant-image{
        width: 40%;
    }
    .jumbotron-bottom{
        display: flex;
        justify-content:space-between
    }

    .tenant-logo{
        width: 60%;
        margin: 0 30px;
    }

    #banner{
        height: 50px;
        /* margin-top: 50px; */
    }
}

@media screen and (max-width: 1000px){
    .card-list{
        grid-template-columns: repeat(1, 1fr);
        margin: 1%;
    }

    .service-list{
        grid-template-columns: repeat(1, 1fr);
        margin: 1%;
    }

    .area-list{
        grid-template-columns: repeat(2, 1fr);
        margin: 1%;
    }

    /* .filter-card-list{
        grid-template-columns: repeat(1, 1fr);
    } */

    .content{
        /* display: inline; */
        /* text-align: center; */
        font-size: 10px;
    }

    .businessName{
        font-size: 15px;
        margin-top: 10px;
    }

    .search{
        padding-top:8%;
        padding-bottom:1%
    }

    #banner{
        width: 100%;
        height: 25vh;
        /* margin-top: 50px; */
    }
}